<template>
  <div>
    <h1>DEBUGGER</h1>     
    <h3>Token : </h3>
    {{ token }}
    <h3>User : </h3>
    {{ user }}
    <h3>Maintenance : </h3>
    <v-row class="pa-2">
      <v-col>
        <v-btn @click="recusValidation">
          Reçus Validation
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col>
        <v-btn @click="recusCdpe">
          Reçus Cdpe
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col>
        <v-btn @click="ebp">
          EBP
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col>
        <v-btn @click="sage">
          SAGE
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex';
import auth from '../auth';

export default {

  data: () => ({
    token: '',
  }),
  computed: {
    ...mapGetters('session', {
      user: 'getUser',
    }),
  },
  created() {
    auth.getToken().then(t => {
      this.token = t;
    });
  },
  methods: {
    recusValidation() {
      document.location = `/maintenance/recuvalidation.jsp?token=${this.token}`;
    },
    recusCdpe() {
      document.location = `/maintenance/recucdpe.jsp?token=${this.token}`;
    },
    ebp() {
      document.location = `/maintenance/ebp.jsp?token=${this.token}`;
    },
    sage() {
      document.location = `/maintenance/sage.jsp?token=${this.token}`;
    },
  },
};
</script>
